<template>
    <div>
        <multiselect
            v-model="selected"
            :options="options"
            label="text"
            track-by="value"
            :class="validateError != '' ? 'box-border-color' : ''"
            :placeholder="$t('select')"
            :multiple="multiple"
            :close-on-select="!multiple"
            :clear-on-select="!multiple"
            :select-label="''"
            :selected-label="''"
            :deselect-label="''"
            @input="handleInput($event, multiple)"
        >
            <template slot="selection" slot-scope="{ values, search, isOpen }">
                <span class="multiselect__single"
                      v-if="values.length && !isOpen">
	                {{ translateNSelected(values) }}
                </span>
            </template>
        </multiselect>
        <span class="invalid-feedback-custom"
              v-show="validateError"
              v-html="validateError"/>
    </div>
</template>

<script>
import PaymentService from "@/services/PaymentService";
import setSelected from "@/helpers/setSelected";
import handleInput from "@/helpers/handleInput";
import translateNSelected from "@/helpers/translateNSelected";

export default {
    props: {
        value: {
            type: Number | Array,
            default: null
        },
        validateError: {
            type: String,
            default: ''
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        textFilter:{
            type: String,
            default: null
        }
    },
    data() {
        return {
            selected: null,
            options: []
        }
    },
    watch: {
        value: function (newValue) {
            this.selected = this.setSelected(newValue, this.options, this.multiple);
        }
    },
    created() {
        this.getOptions();
    },
    methods: {
        setSelected: setSelected,
        handleInput: handleInput,
        translateNSelected: translateNSelected,
        getOptions() {
            const key = 'products';
            let local = this.getWithExpiry(key);
            if (local) {
                this.setOptions(local);
                this.selected = this.setSelected(this.value, this.options, this.multiple)
                return;
            }
            this.options = [];
            PaymentService.products()
                .then(response => {
                    const data = response.data.data;
                    this.setWithExpiry(key, data);
                    this.setOptions(data);
                })
                .then(() => {
                    this.selected = this.setSelected(this.value, this.options, this.multiple);
                })
        },
        setOptions(data){
            data.forEach((item) => {
                if(this.textFilter){
                    if(item.name.search(this.textFilter)===-1){
                        return;
                    }
                }
                this.options.push({
                    value: item.id,
                    text: item.name,
                });
            });
        }
    }
}
</script>
